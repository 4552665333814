import React, {Component} from "react"
import {graphql, Link} from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Layout from "../layouts"
import {Helmet} from "react-helmet";
import { FacebookProvider, Comments } from 'react-facebook';
const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');


const _ = require(`lodash`)


class PostTemplate extends Component {

    stripHtml(html){
        // Create a new div element
        var temporalDivElement = document.createElement("div");
        // Set the HTML content with the providen
        temporalDivElement.innerHTML = html;
        // Retrieve the text property of the element (cross-browser support)
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }


    render() {
        const post = this.props.data.wordpressPost

        if(typeof window === 'undefined') { return null; } let localization = window.location.pathname.split('/');


        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/${post.slug}`}>{element}</Link> :
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/${element}/${post.slug}`}>{element}</Link>
                }

            </li>
        });

        let cats = post.categories.map((el, ix) => {
            return <li key={ix}>{el.name}</li>
        });

        //English

        if (localization.includes("en")) {

            let p = _.find(this.props.data.wordpressPost.wpml_translations, l => l.locale === "en_US");

            return (
                <Layout>
                    <Helmet>
                        <html lang="en" />
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)} />
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)} />
                        <meta property="og:url" content={localization} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="en" />
                        <meta property="og:site_name" content={"KazikKoduje"} />
                        <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>
                    <div className="grid">
                        {/* <ul className="langs col-12">
                            {langs}
                        </ul> */}
                        <article className="post col-10 col-sm-12">
                            <div className="post__meta">
                                <time className="post__time">
                                    {new Date(post.date).toLocaleDateString()}
                                </time>
                                <ul className="post__cats">
                                    {cats}
                                </ul>
                            </div>

                            {
                                (post.featured_media && post.featured_media.localFile) &&
                                <img className={"post__thumb"}
                                     src={post.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                            }

                            <h1 className={"post__title"} dangerouslySetInnerHTML={{__html: p.post_title}}/>
                            <div className={"post__text editor"} dangerouslySetInnerHTML={{__html: p.content}}/>
                            <FacebookProvider appId="123456789">
                                <Comments href={typeof window !== 'undefined' && window.location.href} />
                            </FacebookProvider>

                        </article>
                    </div>
                </Layout>
            )
        }

        //German

        if (localization.includes("de")) {

            let p = _.find(this.props.data.wordpressPost.wpml_translations, l => l.locale === "de_DE");

            return (
                <Layout>
                    <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                        <html lang="de" />
                        <meta name={"title"} content={p.post_title.substring(0, 50)}/>
                        <meta name={"description"} content={this.stripHtml(p.content).substring(0, 300)}/>
                        <meta property="og:title" content={p.post_title.substring(0, 50)} />
                        <meta property="og:description" content={this.stripHtml(p.content).substring(0, 300)} />
                        <meta property="og:url" content={localization} />
                        <meta property="og:type" content="website" />
                        <meta property="og:locale" content="de" />
                        <meta property="og:site_name" content={"KazikKoduje"} />
                        <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                        {/*<meta name="twitter:card" content="summary" />*/}
                        {/*<meta name="twitter:site" content={twitter} />*/}
                    </Helmet>

                    <div className="grid">
                        {/* <ul className="langs col-12">
                            {langs}
                        </ul> */}
                        <article className="post col-10 col-sm-12">
                            <div className="post__meta">
                                <time className="post__time">
                                    {new Date(post.date).toLocaleDateString()}
                                </time>
                                <ul className="post__cats">
                                    {cats}
                                </ul>
                            </div>

                            {
                                (post.featured_media && post.featured_media.localFile) &&
                                <img className={"post__thumb"}
                                     src={post.featured_media.localFile.childImageSharp.fluid.src} alt=""/>
                            }

                            <h1 className={"post__title"} dangerouslySetInnerHTML={{__html: p.post_title}}/>
                            <div className={"post__text editor"} dangerouslySetInnerHTML={{__html: p.content}}/>
                            <FacebookProvider appId="123456789">
                                <Comments href={typeof window !== 'undefined' && window.location.href} />
                            </FacebookProvider>

                        </article>
                    </div>
                </Layout>
            )
        }

        //Polish

        return (
            <Layout>
                <Helmet defaultTitle={"KazikKoduje"} titleTemplate={`%s | KazikKoduje`}>
                    <html lang="pl" />
                    <meta name={"title"} content={post.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={post.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={post.yoast_meta.yoast_wpseo_title} />
                    <meta property="og:description" content={post.yoast_meta.yoast_wpseo_description} />
                    <meta property="og:url" content={localization} />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="pl" />
                    <meta property="og:site_name" content={"KazikKoduje"} />
                    <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                <Helmet>
                    <html lang="pl"/>

                    <title>{post.yoast_meta.yoast_wpseo_title} | Kazik Koduje</title>

                    <meta name={"title"} content={post.yoast_meta.yoast_wpseo_title}/>
                    <meta name={"description"} content={post.yoast_meta.yoast_wpseo_description}/>
                    <meta property="og:title" content={post.yoast_meta.yoast_wpseo_title} />
                    <meta property="og:description" content={post.yoast_meta.yoast_wpseo_description} />
                    <meta property="og:url" content={localization} />
                    <meta property="og:type" content="website" />
                    <meta property="og:locale" content="pl" />
                    <meta property="og:image" content={(post.featured_media && post.featured_media.localFile) && post.featured_media.localFile.childImageSharp.fluid.src} />

                    <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                    <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={img4}/>
                    <meta name="msapplication-TileColor" content="#ffffff"/>
                    <meta name="theme-color" content="#ffffff"/>

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                <div className="grid">

                    {/* <ul className="langs col-12">
                        {langs}
                    </ul> */}

                    <article className="post col-10 col-sm-12">
                        <div className="post__meta">
                            <time className="post__time">
                                {new Date(post.date).toLocaleDateString()}
                            </time>
                            <ul className="post__cats">
                                {cats}
                            </ul>
                        </div>

                        {
                            (post.featured_media && post.featured_media.localFile) &&
                            <img className={"post__thumb"} src={post.featured_media.localFile.childImageSharp.fluid.src}
                                 alt=""/>
                        }

                        <h1 className={"post__title"} dangerouslySetInnerHTML={{__html: post.title}}/>
                        <div className={"post__text editor"} dangerouslySetInnerHTML={{__html: post.content}}/>
                        <FacebookProvider appId="123456789">
                            <Comments href={typeof window !== 'undefined' && window.location.href} />
                        </FacebookProvider>

                    </article>
                </div>
            </Layout>
        )
    }
}

PostTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            categories {
                name
            }
            date
            slug
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
                yoast_wpseo_canonical
            }
            wpml_translations {
                locale
                post_title
                content
                excerpt
                slug
            }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(quality: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`
